/*************************************/
/*           COMPONENTS              */
/*************************************/


/*********** CANCEL BUTTON  **********/

.can-banner-btn{
    z-index:3;
    position: relative;
    background: #fff;
    border: 1px solid grey;
    color:black;
    margin:0 50px 0 0;
  }
  
  .can-banner-btn:hover{
    background: $border-solid;
    // background: white;
    border:1px solid $border-solid;
    transition: background .5s cubic-bezier(0.39, 0.58, 0.57, 1),border .5s ease-in;
    transition-property: background, border;
    transition-duration: 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1), ease-in;
    transition-delay: initial, initial;
    span{
        color:white;
    }
  }
  
  .can-banner-btn span{
    width:200px;
    height:45px;
    // font-family: 'Oswald',Verdana, Tahoma, sans-serif, Verdana, Tahoma, sans-serif;
    font-family: "Raleway", Tahoma, sans-serif;
    font-size: 12px;
    letter-spacing: .22em;
    text-transform: uppercase;
    display: inline-block;
    padding: 30px auto 0px auto;
    background: transparent;
    color: black;
    line-height: 350%;
    text-align: center;
  }

/*********** HOVER BUTTON **********/

.banner-btn{
    z-index:3;
    position: relative;
    background: #d14124;
    border: 1px solid #d14124;
    color:black;
    margin:0;
  }
  
  .banner-btn:hover{
    background: #003057;
    // background: white;
    border:1px solid #003057;
    transition: background .5s cubic-bezier(0.39, 0.58, 0.57, 1),border .5s ease-in;
    transition-property: background, border;
    transition-duration: 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1), ease-in;
    transition-delay: initial, initial;
    span{
        color:white;
    }
  }
  
  .banner-btn span{
    width:200px;
    height:45px;
    // font-family: 'Oswald',Verdana, Tahoma, sans-serif, Verdana, Tahoma, sans-serif;
    font-family: "Raleway", Tahoma, sans-serif;
    font-size: 12px;
    letter-spacing: .22em;
    text-transform: uppercase;
    display: inline-block;
    padding: 30px auto 0px auto;
    background: transparent;
    color: #fff;
    line-height: 350%;
    text-align: center;
  }


/*********** DROPDOWN OPTIONS  **********/

.gs-dd-cont{
    margin-bottom: 0px; 
}

.dropdownlabel{
    font-family: $fontForm;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    margin-bottom: 0.5rem;
    display: block;
    cursor: pointer;
    text-align: left;
}

select {
    width: 100px;
    height:34px;
    text-align: center;
    border: 1px solid $border-solid;
}

select:hover {
    color: #444645;
    border-style: solid;
    border-color:#75b9ef;
}

select:focus{
    outline: none !important;
    border:1px solid #75b9ef;
    box-shadow: 0 0 10px #719ECE;
}

/*********** EXPANDED ROW **********/

.exp-row-1{
    width: 100%;
    div{
        margin-top:10px;
        // border: 1px solid $border-solid;
    }
    tr{
        td{
            width:60px;
            // border: 1px solid $border-solid;
        }
    }
    color:$color-blue;
  }

  /*********** Dynamic Form Fields **********/

.dyn-formfield-btns > .MuiFab-root{
    margin: 10px 10px 10px 0px;
}

.dyn-formfield-cont{
  margin: 0px 0px 20px 0px;
}