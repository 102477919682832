/*********** MESSAGES  **********/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.msg-container {
	display: flex;
	width: 100%;
    margin-top:50px;
	padding: 1.25rem;
	align-items: center;
}
h3 {
	font-size: 1.25rem;
	margin-bottom: .2rem;
}
p {
	font-size: 1rem;
}
.icon {
	margin-right: 1rem;
}
.info {
	margin-bottom: 1.25rem;
	color: #325981;
	border: 1px solid #b6d9fd;
	background-color: #cde5fe;
}
.msg-success {
	margin-bottom: 1.25rem;
	color: #326e41;
	border: 1px solid #c0ddc7;
	background-color: #d4ecda;
}
.upgrade {
	margin-bottom: 1.25rem;
	color: #836a27;
	border: 1px solid #fceabb;
	background-color: #fff2cf;
}
.msg-error {
	margin-bottom: 1.25rem;
	color: #723036;
	border: 1px solid #f5c3c8;
	background-color: #f7d7da;
}
@media only screen and (max-width: 600px) {
    .wrapper {
        width: 100%;
    }
}
