/*********** ADMIN SCREEN  **********/


.cont-admin{
    text-align: center;
    overflow: hidden;
    padding:25px;
}


.login-button{
    margin:10px 0 25px 0;
}

.logout-btn{
    margin:10px 0 25px 0;
}