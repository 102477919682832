/*********** FORM  **********/

.gs-bg-form-2{
    margin: auto;
    background: url('../asset/UDOT_blueback_700x65.png');
    // width: 700px;
    width:inherit;
    min-height:65px;
    text-align: center;
    padding-top: 10px;
}

.gs-bg-form-3{
  margin: 30px auto;
  width: 700px;
  height:100%;
  // border: 1px solid $border-solid;
  color: #003057;
  padding: 10px;
}

@media screen and (min-device-width: 1366px) 
{
  .gs-form-elm-1 {
    margin-top:30px;
  }
  
  .gs-form-elm-2 {
    margin-top:30px;
    padding-top:20px;
  }
}

@media screen and (min-device-width: 700px) and (max-device-width: 1365px)  { 
  .gs-form-elm-1 {
    margin-top:30px;
  }
  
  .gs-form-elm-2 {
    margin-top:30px;
    padding-top:20px;
  }
}

@media screen and (max-device-width: 699px)  { 
  .gs-form-elm-1 {
    margin-top:30px;
  }
  
  .gs-form-elm-2 {
    margin-top:0px;
  }
}


.form-3-pin{
    // border: 1px solid $border-solid;
    width:inherit;
    display: flex;
    .pin-field{
      width: 100%;

    }
    .pin-button{
      margin-top:22px;
      margin-right:100px;
      width: 50%;
      height:36px;
    }

}

.gs-bg-form-4{
    margin: 30px auto;
    width: 700px;
    height:100%;
    // border: 1px solid $border-solid;
    color: #003057;
    padding: 10px;
  }
  
.gs-bg-form-5{
  margin: 30px auto;
  width: 700px;
  height:100%;
  // border: 1px solid $border-solid;
  color: #003057;
  padding: 10px;
}

.gs-bg-form-6{
  margin: 30px auto;
  width: 700px;
  height:100%;
  // border: 1px solid $border-solid;
  color: #003057;
  padding: 10px;
}

.gs-bg-form-7{
  margin: 30px 0;
  width: 700px;
  height:100%;
  // border: 1px solid $border-solid;
  color: #003057;
}

.gs-bg-form-title{
  font-family: $fontForm;
  font-size: larger;
  color:white;
}

.gs-bg-form-subtitle{
  font-family: $fontForm;
  font-size: x-small;
  color:white;
}

/*********** FORM FIELDS  **********/

//COLORS
$border-solid: #D0D2D3;
$colorPrimary: #9b59b6;
$color-danger: #e74c3c;

.gs-ipt-cont{
    margin-bottom: 20px;
}

.formfieldinput {
    // box-shadow: none;
    border: 1px solid $border-solid;
    padding: 0.6rem 0 0.6rem 0;
    width: 90%;
    min-height: 41px;
    font-family: $fontForm;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px; 
}

.formfieldlabel {
    font-family: $fontForm;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    margin-bottom: 0.5rem;
    display: block;
    text-align: left;
  }

  MuiOutlinedInput-input:focus {
    outline: none !important;
    border:1px solid #75b9ef !important;
    box-shadow: 0 0 2px #719ECE !important; 
    height:32px;
}

.MuiInputBase-input {
  height: 1.0em !important;
}
  
/*********** FORM MENUBAR  **********/


.MuiAppBar-colorPrimary {
    background-color: #d14124 !important;
    width:200px !important;
    height:60px;
    top:20px;
    left:20px;
  }
  
  @media (min-width: 600px){
    .MuiToolbar-regular {
        min-height: 62px !important;
    }
  }

  .form-validate-index{
    color:red;
    font-family: $fontForm;
    font-size: 12px;
    margin-top:20px;
    
  }