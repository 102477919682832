/*********** REPORTS  **********/

.gs-bg-rpt-menu{
    // border: 1px $border-solid solid;
}

.gs-bg-rpt-btn{
    margin-top:1.5%;
    margin-right: 1.5%;
    text-align: right;
}

.gs-bg-rpt-table{
    margin: 30px auto;
    width: 97.5%;
    height:100%;
    // border: 1px solid $border-solid;
    color: #003057;
    padding: 10px;
}