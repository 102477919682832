/*********** DEPENDENCIES + VARIABLES  **********/
//reset file/code
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; 
} 

.home-body{
    margin:0;
    padding:0;
}

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$fontFamily: 'Oswald',Verdana, Tahoma, sans-serif, Verdana, Tahoma, sans-serif;
$fontForm: 'Raleway', Tahoma, sans-serif;


//COLORS
$orange: #d14124;
$border-solid: #D0D2D3;
$color-primary: #9b59b6;
$color-danger: #e74c3c;
$color-blue: #183d96;

//COMPONENTS

@import "./scss/header.scss";
@import "./scss/gsform.scss";
@import "./scss/gsreports.scss";
@import "./scss/message.scss";
@import "./scss/admin.scss";
@import "./scss/components.scss";

/*********** ROOT-LEVEL APP  **********/

.App {
  background: url('./asset/map.gif') no-repeat center center fixed;
  overflow: scroll;
  height:100vh;
  // font-family: 'Oswald',Verdana, Tahoma, sans-serif, Verdana, Tahoma, sans-serif;
  font-family: "Raleway", Tahoma, sans-serif;
}

.App-header {
  position:relative;
  background-color: #003057;
  background-image: url("./asset/blue-dots.png");
  background-repeat: repeat 0px 0px;
  width:100%;
  height: 50px;
  // font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2em;
}

.App-logo {
  height: 30px;
  pointer-events: none;
  text-align: left;
  position:relative;
  top:25%;
  left:5%;
}

.App-link {
  color: #61dafb;
}

.MuiOutlinedInput-root {
  border-radius:0px !important;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item{
  padding: 8px 0px 8px 0px !important;
}

.MuiTypography-body1 {
  // font-family: "Oswald", Verdana, Tahoma, sans-serif !important;
  font-family: "Raleway", Tahoma, sans-serif;
  line-height: 20px !important;
  font-size: 13px !important;
}

.MuiFab-primary {
  color: #fff;
  background-color: $orange !important;
  a {
    text-decoration: none;
  }
}

.MuiOutlinedInput-input {
  width:250px !important;
}

/*********** BACKGROUND **********/

.bg-cont-paper{
  background:white;
  width:90%;
  height:400px;
  border: 1px solid #D0D2D3;
  position: relative;
  margin-top:250px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cont-disc{
  margin: auto;
  width: 50%;
  // border: 1px solid $border-solid;
  color: #003057;
  text-align: center;
  padding: 10px;
  font-family: "Raleway", Tahoma, sans-serif;
  font-size: 16px !important;
  line-height: 20px !important;
}

@media only screen and (max-width: 1080px) {
  .cont-disc{
    margin: auto;
    width: 70%;
    padding: 10px;
  }
}

.gs-bg-cont{
  margin-top:50px;
  margin-bottom: 50px;
  margin-left:10%;
  margin-right:10%;
  width:80%;
  background:white;
  border: 1px solid #D0D2D3;
}

.gs-bg-cont-rpt{
  margin: 50px auto;
  width:90%;
  height: inherit;
  background:white;
  border: 1px solid #D0D2D3;
}

/*********** COMPONENTS **********/

//Disclaimer
.gs-disc {
  text-align: justify !important;
  margin-top:25px;
}
