/*********** HEADER  **********/

.header-left {
    // border: 1px white solid;
    float: left;
    width: 30vmax;
    height: 100%;
  }
  
  .header-right {
    position: relative;
    top: 20%;
    left: 7.5%;
    transition: translateX(-50%) translateY(-50%);
    // border: 1px red solid;
    width: 100vmax;
    height: 100%;
    padding-right:50px;
    text-align: start;
    color:white;
    font-size: 1.20em;
  }
  
  @media only screen and (max-width: 800px) {
    .c-header-left {
      width: 15vmax;
    }
    .c-header-right {
      padding-right:0px;
    }
  }